<template>
  <div class="ui-p-4 ui-rounded-md c-bg-white">
    <div
      class="
        ui-flex ui-p-3 ui-rounded-md ui-border-dashed ui-border
        c-border-gray-300
        ui-items-center
      "
    >
      <div class="">
        <audio
          v-show="editAudio"
          class="q-pt-md"
          :src="mediaUrl"
          controls
          ref="audio"
          preload="auto"
        >
          Your browser does not support the <code>audio</code> element.
        </audio>
        <div
          v-if="newAudio"
          class="c-text-input c-text-gray-400 ui-align-middle ui-flex"
        >
          <icon
            name="error"
            class="ui-mr-3"
            filled
            color="--c-color-gray-400"
            size="12"
          ></icon
          >Nenhum arquivo selecionado, arraste e solte aqui ou busque o arquivo
          (Formato aceito: mp3)
        </div>
        <div v-if="uploading" class="c-text-input c-text-gray-400 ui-w-full">
          {{file.name}}
          <div class="ui-flex ui-items-center c-text-b3 c-text-gray-300 ">
          <div class="ui-h-2 ui-w-64 c-bg-gray-200 ui-rounded-md ui-mr-2">
            <div
              class="ui-rounded-md c-bg-green-400 ui-h-full ui-z-10"
              :style="{ width: `${this.uploadProgress}%` }"
            ></div>
          </div>
          {{ uploadProgress }}%
          </div>
        </div>
      </div>
      <div class="c-col">
        <label
          ><c-button outline class="ui-ml-auto" @click="$refs.file.click()"
            >Buscar arquivo <icon name="search" class="c-icon--right"></icon
          ></c-button>
          <input
            class="ui-hidden"
            type="file"
            id="file"
            ref="file"
            accept=".mp3"
            @change="handleFileChange"
          />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { CButton, Icon } from "@estrategiahq/coruja-web-ui";
export default {
  components: {
    CButton,
    Icon,
  },
  data() {
    return {
      file: null,
      bucketInfo: null,
      duration: null,
      uploading: false,
      uploadProgress: 0,
      media:""
    };
  },
  computed: {
    newAudio() {
      return !this.uploading && ( this.mediaUrl === "" && this.uploadProgress === 0);
    },
    editAudio() {
      return !this.uploading && ( this.mediaUrl !== "" || this.uploadProgress === 100);
    },
  },
  props: ["mediaUrl"],
  methods: {
    async getPresignedURL() {
      let result = await this.$s.presigned.getPresignedAudioURL();
      this.bucketInfo = result;
    },
    async uploadFileToS3() {
      let result = await this.$s.presigned.uploadFile(
        this.bucketInfo.url,
        this.file,
        "audio",
        this.onUploadProgress
      );
      return result;
    },
    onUploadProgress(progressEvent) {
      this.uploadProgress = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
    },
    async handleFileChange() {
      if (!this.$refs.file.files[0]) return;
      this.uploading = true;

      try {
        this.file = this.$refs.file.files[0];
        
        this.uploadProgress=0
        const audio = this.$refs.audio
        const reader = new FileReader();
        reader.onload = function (e) {
          audio.src = e.target.result
        }
        audio.onloadedmetadata = (e2) => {
          this.duration = Math.floor(e2.target.duration);
        };
        reader.readAsDataURL(this.file);
        await this.getPresignedURL();
        await this.uploadFileToS3();

        const info = {
          bucketInfo: this.bucketInfo,
          duration: this.duration,
        };
        this.$emit("file-uploaded", info);
        this.uploading = false;

      } catch (err) {
        console.log("ERROR:", err);
        this.uploading = false;
      }
    },
  },
};
</script>
