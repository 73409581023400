<template>
  <div class="ui-flex ui-flex-col ui-w-full">
    <div v-if="!isLoading" class="ui-flex ui-w-full ui-p-4 c-bg-white bottom-line">
     
    
        <c-checkbox :checked="isSelected(value.id)" @change="changeSelection($event,value)" />
        <div
          class="ui-flex ui-flex-grow ui-w-full"
          :style="{ marginLeft: level * 24 + 'px' }"
        >
          <button
            :class="{ 'ui-invisible': !value.has_children }"
            @click="collapsedChange"
          >
            <icon
              :name="collapsed ? 'arrow-right' : 'arrow-down'"
              class="c-icon--left"
              color="var(--c-color-gray-400)"
            />
          </button>

          <div class="ui-flex-grow ui-text-left c-text-btn c-text-gray-400" @click="changeSelection(!isSelected(value.id),value)">
            {{ value.name }}
          </div>
        </div>
      </div>
      <div v-else class="ui-flex ui-w-full ui-p-4 c-bg-white bottom-line"> 
        <c-skeleton-block width="24px" height="24px" class="ui-mr-6"/>
        <c-skeleton-block width="100%" height="24px" />
      </div>

    <div class="content ui-w-full" :class="[collapsed ? 'collapsed' : 'open']">
      <TreeViewItem
        v-for="item in value.items"
        :key="item.id"
        :value="item"
        :level="level + 1"
        :searchChildren="searchChildren"
        :isSelected="isSelected"
        :changeSelection="changeSelection"
        :isLoading="isLoading"
      />
    </div>
  </div>
</template>

<script>
import { Icon, CCheckbox,CSkeletonBlock } from "@estrategiahq/coruja-web-ui";
export default {
  name: "TreeViewItem",
  components: { Icon, CCheckbox,CSkeletonBlock },
  props: {
    value: { type: Object, require: true },
    level: { type: Number, default: 0 },
    searchChildren: { type: Function, require: true },
    isSelected: { type: Function, require: true },
    changeSelection: { type: Function, require: true },
    isLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      collapsed: true,
    };
  },
  mounted(){
    this.collapsed=true
  },
  methods: {
    collapsedChange() {
      this.collapsed = !this.collapsed;
      if (
        !this.collapsed &&
        (!this.value.items || this.value.items.length === 0)
      )
        this.searchChildren(this.value.id);
    },
  },
};
</script>

<style scoped>
.open {
  max-height: 575px;
}

.collapsed {
  max-height: 0;
}

.content {
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.bottom-line {
  box-shadow: inset 0px -1px 0px rgba(153, 153, 153, 0.24);
}
</style>