<template>
<div>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <div class="ui-p-6 c-bg-gray-100" @drop.prevent>
      <c-link
        class="
          ui-flex ui-text-left ui-p-0 ui-no-underline
          c-text-b2
          ui-mb-2
          c-text-gray-400
        "
        @click="cancel()"
      >
        <icon
          name="ArrowLeft"
          color="var(c-text-gray-400)"
          class="--icon-left"
        />
        Voltar para Lista de faixas
      </c-link>
      <div
        class="ui-pb-lg ui-pt-lg c-text-h2 ui-text-left c-text-gray-400"
        v-if="track.id"
      >
        Editar faixa:
      </div>
      <div
        class="ui-pb-lg ui-pt-lg c-text-h2 ui-text-left c-text-gray-400"
        v-else
      >
        Criar nova faixa
      </div>
      <div
        class="ui-pb-lg ui-pt-lg c-text-h3 ui-text-left c-text-gray-400"
        v-if="track.id"
      >
        {{ track.title }}
      </div>
      <div class="ui-px-4 ui-py-6 ui-mt-8 ui-mb-12 c-bg-white ui-rounded-md">
        <div class="c-row">
          <div class="c-col">
            <ValidationInput
              class="ui-mb-6"
              label="Insira o título da faixa"
              v-model="track.title"
              rules="required|title"
            />
          </div>
        </div>
        <div class="c-row ui-my-6">
          <div class="c-col">
            <teacher-select v-model="track.teachers"></teacher-select>
          </div>
        </div>
        <div class="c-row ui-my-6">
          <div class="c-col">
            <catalog-select v-model="track.subjects"></catalog-select>
          </div>
        </div>
      </div>

      <div class="c-text-h3 c-text-gray-400 ui-mt-12 ui-mb-4 ui-text-left">
        Adicione uma faixa de áudio
      </div>
      <TrackUploader
        :mediaUrl="track.media_url"
        @file-uploading="onFileUpload"
        @file-uploaded="onFileUploaded"
      />
    </div>
      <actions-footer :cancelRoute="'/'" @action-click="handleActionClick">
        <template v-slot:actions>
          <c-button class="ui-mr-4" outline @click="save(handleSubmit)"
            >Somente salvar</c-button
          >
          <c-button @click="saveAndNext(handleSubmit)"
            >Salvar e ir para álbuns
            <icon name="next" outline class="c-icon--right"
          /></c-button>
        </template>
      </actions-footer>
  </ValidationObserver>
  </div>
</template>

<script>
import { CButton, Icon, CLink } from "@estrategiahq/coruja-web-ui";

import ValidationInput from "../../components/ValidationInput.vue";
import TrackUploader from "../../components/TrackUploader.vue";
import TeacherSelect from "../../components/TeacherSelect.vue";
import CatalogSelect from "../../components/CatalogSelect.vue";
import ActionsFooter from "../../components/ActionsFooter.vue";
import { required } from "vee-validate/dist/rules";

import { extend, ValidationObserver } from "vee-validate";

extend("title", (value) => {
  if (value.length >= 3 && value.length <= 250) {
    return true;
  }

  return "O título é obrigatório e deve conter no mínimo 3 e no máximo 250 caracteres";
});

extend("required", {
  ...required,
  message:
    "O título é obrigatório e deve conter no mínimo 3 e no máximo 250 caracteres",
});
export default {
  components: {
    CButton,
    CLink,
    Icon,
    ValidationInput,
    ValidationObserver,
    TrackUploader,
    TeacherSelect,
    CatalogSelect,
    ActionsFooter,
  },
  data() {
    return {
      track: {
        id: null,
        title: "",
        media: "",
        mediaKey: null,
        media_url: "",
        duration: null,
        teachers: [],
        subjects: [],
      },
      bucketInfo: null,
      isUploading: false,
    };
  },
  created() {
    this.track.id = this.$route.params.id || null;
  },
  mounted() {
    if (this.track.id) {
      this.fetchTrack(this.track.id);
    }
  },
  methods: {
    async createOrEditTrack() {
      if (this.track.id) {
        await this.updateTrack(this.track.id);
      } else {
        await this.createTrack();
      }
    },
    async save(handleSubmit) {
      await handleSubmit(async () => {
        try {
          await this.createOrEditTrack();
          this.$emit("toast", {
            text: "Faixa salvo com sucesso",
            position: "top",
            timeout: 3000,
          });
          this.$router.push({ name: "tracks" });
        } catch (error) {
          //Deve ser tratado após revisão de backend
          console.log(error);
          this.$emit("toast", {
            text: "Falha ao salvar Faixa",
            position: "top",
            type: "error",
            timeout: 3000,
          });
        }
      });
      window.scrollTo(0, 0);
    },
    async saveAndNext(handleSubmit) {
      await handleSubmit(async () => {
        try {
          await this.createOrEditTrack();
          this.$emit("toast", {
            text: "Faixa salvo com sucesso",
            position: "top",
            timeout: 3000,
          });
          this.$router.push({ name: "albums" });
        } catch (error) {
          //Deve ser tratado após revisão de backend
          console.log(error.response);
          this.$emit("toast", {
            text: "Falha ao salvar Faixa",
            position: "top",
            type: "error",
            timeout: 3000,
          });
        }
      });
      window.scrollTo(0, 0);
    },
    async fetchTrack() {
      let result = await this.$s.track.fetchTrack(this.track.id);
      const track = result.data;
      this.track.title = track.title;
      this.track.teachers = track.teachers;
      this.track.subjects = track.subjects;
      this.track.duration = track.duration;

      result = await this.$s.track.getTrackUrl(track.id);
      this.track.media_url = result.data.url;
      const key = `audios/${result.data.url.split("/")[4]}.mp3`;
      this.track.mediaKey = key;

      this.track.media = track.media;
    },
    onFileUpload() {
      this.isUploading = true;
    },
    onFileUploaded(data) {
      this.bucketInfo = data.bucketInfo;
      this.track.duration = data.duration;
      this.track.mediaKey = `${this.bucketInfo.key}.mp3`;
      this.isUploading = false;
    },
    async createTrack() {
      const data = {
        title: this.track.title,
        teachers: this.track.teachers,
        subjects: this.track.subjects,
        media: this.track.mediaKey,
        duration: this.track.duration,
      };
      await this.$s.track.createTrack(data);
    },
    async updateTrack() {
      const data = {
        title: this.track.title,
        teachers: this.track.teachers,
        subjects: this.track.subjects,
        media: this.track.mediaKey,
        duration: this.track.duration,
      };
      await this.$s.track.updateTrack(this.track.id, data);
    },
    cancel() {
      this.$router.push({ name: "tracks" });
    },
    handleActionClick(type) {
      const actions = {
        CANCEL: () =>
          window.history.length > 1
            ? this.$router.go(-1)
            : this.$router.push({ name: "tracks" }),
      };
      return actions[type] && actions[type]();
    },
  },
};
</script>

<style>
</style>