<template>
  <div>
    <c-dropdown-action
      name="teacher-select"
      class="
        ui-p-4 ui-border ui-rounded
        c-border-gray-200
        focus:c-border-primary
        w-full
        active:c-border-primary
      "
    >
      <template slot="label" slot-scope="props">
        <div class="ui-mr-2 ui-w-full ui-flex">
          <p class="c-text-label c-text-gray-300 ui-text-left">
            Busque ou selecione o professor ou os professores
          </p>
          <icon
            :name="props.props.isOpen ? 'arrowUp' : 'arrowDown'"
            color="gray"
            class="
              c-icon--right
              ui-ml-auto ui-transition-all ui-duration-100 ui-easy-in
            "
          />
        </div>
      </template>
      <template>
        <div class="c-bg-gray-100" :style="getWidth()" @click.stop="">
          <div class="ui-p-2">
            <c-search-bar v-model="filter"></c-search-bar>
          </div>
          <div class="max-h-options ui-overflow-auto">
            <div>
              <div
                class="ui-flex c-bg-white ui-p-4 bottom-line"
                v-for="teacher in teachers"
                :key="teacher.id"
              >
                <c-checkbox
                  :checked="isSelected(teacher, value)"
                  @change="selectTeacher($event, teacher)"
                  :label="`${teacher.full_name} - ${ teacher.email }`"
                ></c-checkbox>
              </div>
            </div>
          </div>
        </div>
      </template>
    </c-dropdown-action>
    <div class="ui-flex ui-mt-2 ">
    
      <c-pill
        v-for="teacher in value"
        :key="teacher.id + teacher.user_id"
        :label="teacher.name"
        removable
        outline
        color="var(--c-color-gray-400)"
        @remove="removePill(teacher)"
        class="ui-ml-2"

      />

    </div>
  </div>
</template>

<script>
import {
  CDropdownAction,
  CSearchBar,
  CCheckbox,
  Icon,
  CPill,
} from "@estrategiahq/coruja-web-ui";
export default {
  components: {
    CDropdownAction,
    CSearchBar,
    CCheckbox,
    Icon,
    CPill,
  },
  props: {
    id: {
      type: String,
      default: "teacher-select",
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options2: [
        {
          label: "Sala VIP",
          value: "ADD",
          checked: true,
        },
        {
          label: "Lives",
          value: "EDIT",
          checked: false,
        },
        {
          label: "Monitoria",
          value: "REMOVE",
          checked: false,
        },
      ],
      value2: {
        label: "Todos os grupos",
        value: "ADD",
      },
      groups: null,
      teachers: null,
      filter: "",
      timeout: null,
    };
  },
  async mounted() {
    await this.fetchTeachers();
  },
  watch: {
    async filter() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => await this.fetchTeachers(), 300);
    },
  },
  methods: {
    async fetchTeachers() {
      try {
        if (!this.groups) {
          let result = await this.$s.accounts.fetchGroups("");
          this.groups = result.data;
        }
        let teachersGroup = this.groups.filter((x) =>
          x.name.match(/professor/i)
        );
        let search = "";
        if (teachersGroup) {
          search =
            "?groups[]=" + teachersGroup.map((x) => x.id).join("&groups[]=");
        } else {
          search =
            "?groups[]=" + this.groups.map((x) => x.id).join("&groups[]=");
        }
        if (this.filter) {
          search += `&query=${encodeURIComponent(this.filter)}`;
        }
        let result = await this.$s.accounts.fetchUsers(search);
        this.teachers = result.data;
      } catch (error) {
        console.log(error.response);
      }
    },
    isSelected(teacher, selectedTeachers) {
      return selectedTeachers.findIndex((x) => x.user_id === teacher.id) !== -1;
    },
    selectTeacher(isSelected, teacher) {
      if (isSelected) {
        this.$emit(
          "input",
          this.value.concat({ user_id: teacher.id, name: teacher.full_name })
        );
      } else {
        this.$emit(
          "input",
          this.value.filter((x) => x.user_id !== teacher.id)
        );
      }
    },
    removePill(teacher) {
      this.$emit(
        "input",
        this.value.filter(
          (x) => x.id + x.user_id !== teacher.id + teacher.user_id
        )
      );
    },
    getWidth() {
      let el = document.getElementById("teacher-select");
      if (el) {
        return { width: el.offsetWidth + "px" };
      }
      return { width: "500px" };
    },
  },
};
</script>

<style>
#teacher-select {
  width: 100%;
  display: block;
}
.bottom-line {
  box-shadow: inset 0px -1px 0px rgba(153, 153, 153, 0.24);
}
.pill-text-color {
  color: var(--c-color-gray-400) !important;
}
.max-h-options{
  max-height: 224px;

}
</style>