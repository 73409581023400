<template>
  <div>
    <div class="ui-flex-grow ui-mb-12" />
    <footer class="ui-flex c-bg-white ui-p-6 ui-z-10" elevated>
      <c-button
        id="cancel-button"
        color="primary"
        outline
        label="Cancelar"
        @click="emitAction(actionTypes.CANCEL)"
        v-if="cancelRoute.length !== 0"
        >Cancelar</c-button
      >
      <div v-else />
      <div class="ui-flex-grow"></div>
      <div class="ui-flex">
        <slot name="actions">
          <c-button
            id="ok-button"
            color="primary"
            :label="getLabel"
            :disable="okDisabled"
            @click="emitAction(actionTypes.OK)"
            >{{ getLabel }}</c-button
          >
        </slot>
      </div>
    </footer>
  </div>
</template>

<script>
import { CButton } from "@estrategiahq/coruja-web-ui";

const ACTION_TYPES = {
  CANCEL: "CANCEL",
  OK: "OK",
};

export default {
  components: {
    CButton,
  },
  name: "ActionsFooter",
  props: {
    readonly: { type: Boolean, default: false },
    cancelRoute: { type: String, required: false },
    editRoute: { type: String, required: false },
    okDisabled: { type: Boolean, required: false },
    okLabel: { type: String, required: false },
  },
  computed: {
    getLabel: function () {
      return this.okLabel || "Salvar";
    },
  },
  data: () => ({
    actionTypes: ACTION_TYPES,
  }),
  methods: {
    emitAction(type) {
      if (type === ACTION_TYPES.OK && this.readonly) {
        this.$router.push({
          name: this.editRoute,
          params: { ...this.$route.params },
        });
        return;
      }
      this.$emit("action-click", type);
    },
  },
};
</script>